import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/typography",
  "title": "Typography tokens - Usage",
  "navTitle": "Typography"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Typography can be used to organise information and help users find their way around our services. For example, using heading levels clearly and consistently helps users to understand the hierarchy and structure of the page.`}</p>
    <p>{`Typography is also an essential part of the City of Helsinki's brand identity. Helsinki Design system typography is based on the proprietary `}<strong>{`Helsinki Grotesk`}</strong>{` typeface. Preferably, no other typefaces should be used. The font licenses can be purchased from the `}<ExternalLink href="https://camelot-typefaces.com/helsinki-grotesk" mdxType="ExternalLink">{`Camelot Typefaces website`}</ExternalLink>{`.`}</p>
    <h2 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h2" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use black (`}<inlineCode parentName="strong">{`--color-black-90`}</inlineCode>{`) or white (`}<inlineCode parentName="strong">{`--color-white`}</inlineCode>{`) text colour`}</strong>{` depending on the background. Other text colours should be used intentionally, if at all.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use typography consistently`}</strong>{` throughout your service, to ensure that the content hierarchy and structure are clear and easily understandable.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use heading levels semantically.`}</strong>{` For example after `}<strong parentName="li">{`h1`}</strong>{`, next heading on the same page should be `}<strong parentName="li">{`h2`}</strong>{`, not `}<strong parentName="li">{`h3`}</strong>{`. If needed, the appearance and visual hierarchy of headings can be altered separately from the semantic structure with the font size tokens.`}</li>
    </ul>
    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">{`Always check that sufficient colour contrast between text and its background is maintained. All colour choices should comply with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#contrast-minimum" mdxType="ExternalLink">{`WCAG 2.1 Standard at AA Level contrast ratios`}</ExternalLink>{`.`}</li>
      <li parentName="ul">{`Avoid placing text over images, especially in small sizes.`}</li>
    </ul>
    <h2 {...{
      "id": "usage-1",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage-1",
        "aria-label": "usage 1 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`The HDS offers two different sets of font size tokens for heading and body text. The tokens define the font size in rem values.`}</p>
    <h3 {...{
      "id": "heading-sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Heading sizes`}<a parentName="h3" {...{
        "href": "#heading-sizes",
        "aria-label": "heading sizes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The heading font size tokens are heading level agnostic. This allows you to fit the `}<strong parentName="p">{`heading scale`}</strong>{` to the specific needs of the service. In addition to predefined `}<em parentName="p">{`default`}</em>{` and `}<em parentName="p">{`small`}</em>{` heading scales, custom scales can be also used.`}</p>
    <p>{`You can use `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/utils/helpers.css" mdxType="ExternalLink">{`helper classes provided in the Core package`}</ExternalLink>{` to match each heading size with the correct line height and letter spacing.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Heading size`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`PX value`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`REM value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Heading XXL`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`64px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xxl" aria-label="Visualised heading XXL example">{`Heading XXL`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Heading XL`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xl" aria-label="Visualised heading XL example">{`Heading XL`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Heading XL Mobile`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xl-mobile" aria-label="Visualised heading XL Mobile example">{`Heading XXL Mobile`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Heading L`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-l" aria-label="Visualised heading L example">{`Heading L`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Heading M`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-m" aria-label="Visualised heading M example">{`Heading M`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Heading S`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`20px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.25rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-s" aria-label="Visualised heading S example">{`Heading S`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Heading XS`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`18px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.125rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xs" aria-label="Visualised heading XS example">{`Heading XS`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Heading XXS`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xxs" aria-label="Visualised heading XXS example">{`Heading XSS`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Heading sizes]`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "heading-scales",
      "style": {
        "position": "relative"
      }
    }}>{`Heading scales`}<a parentName="h3" {...{
        "href": "#heading-scales",
        "aria-label": "heading scales permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The heading font size tokens allow two different heading scales to be used. The `}<strong parentName="p">{`default heading scale`}</strong>{` is suitable for desktop screen sizes. The `}<strong parentName="p">{`small heading scale`}</strong>{` is used on mobile devices and it can be also used on information-heavy user interfaces (e.g. admin panels).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Heading level`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default scale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Small scale`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading 1 (`}<inlineCode parentName="td">{`h1`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xxl" aria-label="Visualised heading XXL example">{`Heading XXL (64px)`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xl-mobile" aria-label="Visualised heading XL Mobile example">{`Heading XL-Mobile (40px)`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading 2 (`}<inlineCode parentName="td">{`h2`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xl" aria-label="Visualised heading XL example">{`Heading XL (48px)`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-l" aria-label="Visualised heading L example">{`Heading L (32px)`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading 3 (`}<inlineCode parentName="td">{`h3`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-l" aria-label="Visualised heading L example">{`Heading L (32px)`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-m" aria-label="Visualised heading M example">{`Heading M (24px)`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading 4 (`}<inlineCode parentName="td">{`h4`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-m" aria-label="Visualised heading M example">{`Heading M (24px)`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-s" aria-label="Visualised heading S example">{`Heading S (20px)`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading 5 (`}<inlineCode parentName="td">{`h5`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-s" aria-label="Visualised heading S example">{`Heading S (20px)`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xs" aria-label="Visualised heading XS example">{`Heading XS (18px)`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading 6 (`}<inlineCode parentName="td">{`h6`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xs" aria-label="Visualised heading XS example">{`Heading XS (18px)`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><div className="heading-xxs" aria-label="Visualised heading XXS example">{`Heading XXS (16px)`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Heading scales]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "body-text-sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Body text sizes`}<a parentName="h3" {...{
        "href": "#body-text-sizes",
        "aria-label": "body text sizes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Body font size tokens allow more subtle variations of body text sizes for improved content hierarchy and legibility.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Heading size`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`PX value`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`REM value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Body XL`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`20 px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Emphasising short introductory texts and other subtitles.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Body L`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`18 px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.125`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Body text default for desktop screens.`}</strong>{` Improving legibility of essential components, e.g. Text input and other form fields.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Body M`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16 px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Body text default for mobile screens.`}</strong>{` Can also be used on text-heavy user interfaces as the default desktop body text size.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Body S`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`14 px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conserving space in data-heavy layouts and small components, e.g. Status label.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Body text sizes]`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`When choosing the body text size, start with the default `}<inlineCode parentName="p">{`Body M (16 px)`}</inlineCode>{` token. It is a good starting point for most text-heavy user interfaces. This is also the minimum for the body text size in Helsinki services. This body text size can be used on all screen sizes.`}</p>
    <p>{`The smaller `}<inlineCode parentName="p">{`Body S (14px)`}</inlineCode>{` token should only be used in secondary text content and inside components. `}</p>
    <p>{`If your service is not text-heavy, you can experiment with using the `}<inlineCode parentName="p">{`Body L, 18px`}</inlineCode>{` token as the default body text size. In this case, it is recommended to use a smaller `}<inlineCode parentName="p">{`Body M (16 px)`}</inlineCode>{` token for mobile breakpoints `}<inlineCode parentName="p">{`breakpoint-s`}</inlineCode>{` and `}<inlineCode parentName="p">{`breakpoint-xs`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "line-height",
      "style": {
        "position": "relative"
      }
    }}>{`Line height`}<a parentName="h3" {...{
        "href": "#line-height",
        "aria-label": "line height permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Line height tokens can be used to control the spacing between lines of text. The tokens define the ratio between line height and element font size. Note that HDS heading and text tokens use their line-height values to ensure line-height applies to 4 point grid. You can use `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/utils/helpers.css" mdxType="ExternalLink">{`helper classes provided in the Core package`}</ExternalLink>{` to match each heading and body text style with the correct line-height.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Line height size`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Ratio`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Line height S`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Buttons, navigation, and other UI components.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Line height M`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead texts and short paragraphs (no longer than 2-3 sentences).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Line height L`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optimal for longer text content and extended reading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Line height XL`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Smaller text sizes or if you need to emphasise text (i.e. quotes).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 4:Line height sizes]`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "font-weights",
      "style": {
        "position": "relative"
      }
    }}>{`Font weights`}<a parentName="h3" {...{
        "href": "#font-weights",
        "aria-label": "font weights permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS typography uses three font weights.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Weight name`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`CSS font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Regular`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Body text default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medium`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for labels in components e.g. Button and form components.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bold`}</strong></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`700`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used to emphasise text.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 5:Font weights]`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      